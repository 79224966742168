import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EMPTY_STRING } from '../../../../../tech-shared/src';
import { TextInputComponent } from '../../form/text-input/text-input.component';
import { CloseIconComponent } from '../../icons/close-icon/close-icon.component';
import { SearchIconComponent } from '../../icons/search-icon/search-icon.component';

@Component({
  selector: 'ods-search-field',
  standalone: true,
  imports: [CommonModule, TextInputComponent, SearchIconComponent, CloseIconComponent],
  template: `<ods-text-input
    [label]="label"
    [fieldControl]="control"
    [placeholder]="placeholder"
    [withPrefix]="true"
    [withSuffix]="true"
    (clickEmitter)="inputClicked.emit()"
    role="combobox"
  >
    <ods-search-icon prefix aria-hidden="true" aria-label="Suchfeld" />
    <button suffix *ngIf="control.value" (click)="clearInput()" aria-label="Eingabe löschen">
      <ods-close-icon class="fill-primary hover:fill-primary-hover" />
    </button>
  </ods-text-input>`,
})
export class SearchFieldComponent {
  @Input() label: string = EMPTY_STRING;
  @Input() placeholder: string = EMPTY_STRING;
  @Input() control = new FormControl(EMPTY_STRING);

  @Output() inputClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  clearInput() {
    this.control.setValue(EMPTY_STRING);
  }
}
